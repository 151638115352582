import { Wrap, Avatar, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter, Box, Button, FormControl, TableContainer, Heading, Thead, Tr, Th, Td, Table, Flex, Tbody, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, FormLabel, Input, InputGroup, InputLeftElement, useToast, Text, Select, InputLeftAddon } from "@chakra-ui/react";
import Sidebar from "../../components/Sidebar"
import api from "../../services/api";
import React, { useEffect, useRef, useState } from "react";
import { ArrowLeftIcon, ArrowRightIcon, SearchIcon } from "@chakra-ui/icons";
import debounce from "lodash.debounce";
import { useCallback } from "react";

const PriceByCustomer = () => {

    // state for paginations 
    const [dataPrice, setDataPrice] = useState([]);
    const [page, setPage] = useState(1);

    // state for save data customers
    const [nameCustomers, setNameCustomers] = useState([]);

    // state for save data fish 
    const [nameOfFish, setNameOfFish] = useState([]);

    // searchName for getAllPriceByCustomerName
    const [searchName, setSearchName] = useState("");

    // state for handle select name customer on add data
    const [selectedNameCust, setSelectedNameCust]= useState(0);

    // state for handle select name fish on add data
    const [selectedNameFish, setSelectedNameFish] = useState(0);

    // state for save range prices
    const [rangePriceMin, setRangePriceMin] = useState(0);
    const [rangePriceMax, setRangePriceMax] = useState(0);

    // state for save prices
    const [fixPrice, setFixPrice] = useState(0);

    // state id price by customers
    const [idPrice, setIdPrice] = useState();

    // state for save get data by id for updated
    const [dataPriceById, setDataPriceById] = useState();

    // modal open and close add
    const [openAddModal, setOpenAddModal] = useState(false);

    // modal open and close edit
    const [openEditModal, setOpenEditModal]= useState(false);

    // confirmation alert open 
    // delete
    const [openAlertDelete, setOpenAlertDelete] = useState(false);

    const [profile, setProfile] = useState({}); // set profile users

    // toast for alert 
    const toast = useToast();


    // handle paginated
    const handleNextPage = async() => {
        if (page !== dataPrice.totalPages) {
            setPage(page + 1);
        }else if (searchName !== "" && page !== dataPrice.totalPages) {
            await getAllPriceByCustomerName(searchName, page + 1);
        }else{
            toast({
                title: 'Warnings...!',
                description: 'You are already on the last page.',
                status: 'warning',
                duration: 1500,
                isClosable: true,
                position: 'top',
            });
        }
    };

    const handlePreviousPage = async() => {
        if (page > 1) {
            setPage(page -1)
        }

        if (searchName !== "") {
            await getAllPriceByCustomerName(searchName, page - 1);
        }
    };

    // get all data price by customer 
    const getAllPriceData = async () => {
        try {
            const response = await api.get(`/api/v5/price/page?page=${page}`);
            setDataPrice(response.data.payload);
        } catch (error) {
            console.log("Error Fetching data", error);
            toast({
                title: 'Error.!',
                description: 'Internal Server Error',
                status: 'error',
                duration: 1500,
                isClosable: true,
                position: 'top',
            });
        }
    };

    // get name before throwing name on function getCustomersByName 
    const handleSearchNameForAdd = useCallback(
        debounce((e) => {
            const name = e.target.value;

            if (name !== "") {
                getCustomersByName(name)
            }
        }, 1000),[]
    );

    // get data customers by name 
    const getCustomersByName = async (name) => {
        try {
            const response = await api.get(`/api/v4/cust/search/name?name=${name}`);
            setNameCustomers(response.data.payload.data);
        } catch (error) {
            console.log("Error Fetching Data", error);
            toast({
                title: 'Error.!',
                description: `${error.response.data.message}`,
                status: 'error',
                duration: 1500,
                isClosable: true,
                position: 'top',
            });
        }
    };

    // get name before throwing name on function getFishByName 
    const handleSearchNameForAddFish = useCallback(
        debounce((e) => {
            const name = e.target.value;

            if (name !== "") {
                getFishByName(name);
            }
        }, 1000),[]
    );

    // get all fish name
    const getFishByName = async(name) => {
        try {
            const response = await api.get(`/api/v3/fish/search/name?name=${name}`);
            setNameOfFish(response.data.payload);
        } catch (error) {
            console.log("Error Fetching Data", error);
            toast({
                title: 'Error.!',
                description: `${error.response.data.message}`,
                status: 'error',
                duration: 1500,
                isClosable: true,
                position: 'top',
            });
        }
    };

    // function for set timeout on change in search bar price by customers

    const searchNameRef = useRef();

    const handleSearchName = useCallback(
        debounce( async (e) => {
            const newName = e.target.value;
            setSearchName(newName);

            if (searchNameRef.current) {
                searchNameRef.current.value = newName;
            }

            if (newName !== "") {
                setSearchName(newName);
            }

            if (!newName) {
                setSearchName("")
            }
        },1000),[],
    );

    // get price cust by name 

    const getAllPriceByCustomerName = async (name, page=1) => {
        try {
            // console.log(page)

            const response = await api.get(`/api/v5/price/name/page?name=${name}&page=${page}`)
            setDataPrice(response.data.payload)
        } catch (error) {
            console.log("Error Fetching data", error);
            setDataPrice([])
            if (error.response.data.status_code !== 200) {
                    toast({
                        title: 'Cannot Show Data',
                        description: `${error.response.data.message}`,
                        status: 'error',
                        duration: 1500,
                        isClosable: true,
                        position: "top",
                    });
            }
        }
    };

    // fetch data to get range price
    const GetPricesRange = async () => {
        try {
            // get data fish id
            const fishId = selectedNameFish;
            
            // checking data customers and fish must be valid 
            if (fishId !== 0) {
                const response = await api.get(`/api/v3/fish/${fishId}`);

                // set state for value price 
                if (response.data.status_code === 200){
                    setRangePriceMin(response.data.payload.min_price);
                    setRangePriceMax(response.data.payload.max_price);
                }
            };

        } catch (error) {
            console.log("Error Fetching data", error);
            if (error.response.data.status_code !== 200) {
                    toast({
                        title: 'Cannot Show Data',
                        description: `${error.response.data.message}`,
                        status: 'error',
                        duration: 1500,
                        isClosable: true,
                        position: "top",
                    });
            }
        }
    };

    // console.log(rangePriceMin)

    // handle get prices input
    const handlePriceInput = (e) => {
        setFixPrice(e.target.value);
    };


    // handle update click atau edit click
    const handleEditClick = async(Id) => {
        try {
            // check if id is not defind
            if (!Id) {
                toast({
                    title: 'Error',
                    description: `Error Edit Data Id Is Not Defind`,
                    status: 'error',
                    duration: 1500,
                    isClosable: true,
                    position: "top",
                });
            }

            // fetch api
            const response = await api.get(`/api/v5/price/search/${Id}`);

            // 
            if (response.data.status_code === 200) {
                setDataPriceById(response.data.payload[0]);
            }
        } catch (error) {
            console.log("Error Fetching data", error);
            if (error.response.data.status_code !== 200) {
                toast({
                    title: 'Error',
                    description: `${error.response.data.message}`,
                    status: 'error',
                    duration: 1500,
                    isClosable: true,
                    position: "top",
                });
            }
        }
    };

    // get price set timeout
    const handleClickRangePriceEdit = (fishId) => {
        setSelectedNameFish(fishId);
        setTimeout(() => {
           GetPricesRange(); 
        }, 500);
    }

    // get data user by token 
    const getUsers = async() => {
        const token = localStorage.getItem('access_token');
        // console.log(token)
        try {
            if (token !== null) {
                const response = await api.get(`/api/v1/users/user/profile/${token}`);
                if (response.data.status_code === 200) {
                    setProfile(response.data.payload);
                }
                // console.log(response.data.payload)
            }
        } catch (error) {
            console.log("Error Fetching Data", error);
            toast({
                title: 'Error.!',
                description: `${error.response.data.message}`,
                status: 'error',
                duration: 1500,
                isClosable: true,
                position: 'top',
            }); 
        }
    }

    // console.log(fixPrice);

    useEffect(() => {
        if (searchName === "") {
            getAllPriceData();
        }else{
            getAllPriceByCustomerName(searchName, page)
        }
        getUsers();
    }, [page, searchName]);

    return(
        <>
        <Box bgColor={"#F5F7FA"} bgSize={"cover"} height={"100vh"} display={"flex"}>
                <Sidebar/>

                <Box margin={"50px"} width={"7xl"} display={"flex"} flexDirection={"column"} marginLeft={"280px"}>
                   
                    <Box marginLeft={"10px"} marginTop={"20px"} display={"flex"} flexDir={"row"}>
                        <Box> 
                            <Heading>Price By Customer</Heading>    
                        </Box>
                        
                        <Box marginLeft={"auto"}>
                            <Wrap spacing={"15px"}>
                                <Text fontWeight={"semibold"} fontSize={"xl"} marginTop={"9px"}>{profile?.full_name ? profile.full_name.split(' ').slice(0, 2).join(' ') : ''}</Text>
                                <Avatar name={profile?.full_name ?? ''}></Avatar>
                            </Wrap>
                        </Box>
                    </Box>

                    <Box padding={"30px"} display={"flex"} flexDirection={"column"} bgColor={"#FFFFFF"} borderRadius={"30px"} marginTop={"40px"} minHeight={"78vh"}>
                        <Flex flexDir={"row"} marginLeft={"13px"} gap={800}>
                            <Box alignSelf={"flex-start"} width={"190px"}>
                                <Text marginBottom={"10px"}>Customer Name</Text>
                                <InputGroup>
                                    <InputLeftElement>
                                        <SearchIcon/>
                                    </InputLeftElement>

                                    <Input placeholder="Customer Name" name="name" onChange={handleSearchName} ref={searchNameRef}></Input>
                                </InputGroup>
                                </Box>
                        </Flex>
                        
                        <Box bgColor={"whitesmoke"} boxSize={"6xl"} marginTop={"22px"} borderRadius={"20px"} marginLeft={"10px"} paddingBottom={"20px"} minHeight={"51.5vh"} maxHeight={"51.5vh"}>
                        <TableContainer>
                                <Table variant={"striped"} >
                                    <Thead>
                                        <Tr>
                                            <Th>No</Th>
                                            <Th>Customer Name</Th>
                                            <Th>Fish Name</Th>
                                            <Th>Price</Th>
                                            <Th>Action</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {dataPrice.data && dataPrice.data.map((prices, index) => (
                                        <Tr key={prices.id}>
                                            <Td>{(dataPrice.currentPages - 1) * 5 + (index + 1)}</Td>
                                            <Td>{prices.customer.name}</Td>
                                            <Td>{prices.fish.name}</Td>
                                            <Td>{`Rp. ${prices.price.toLocaleString('id-ID')}`}</Td>
                                            <Td>
                                                <Button size={"sm"} colorScheme="blackAlpha" borderRadius={"40px"} onClick={() => {setOpenEditModal(true); handleEditClick(prices.id)}} >Detail</Button>  
                                            </Td>                                
                                        </Tr>
                                        ))}
                                    </Tbody>
                                </Table>
                            </TableContainer>
                        </Box>

                        <Flex marginTop={"20px"} gap={"10px"} marginLeft={"15px"}>
                            <Box as="div" marginTop={"7px"} justifyContent={"flex-start"}>
                                <Text fontWeight={"semibold"} fontSize={"sm"}>Current Pages : {dataPrice.currentPages}</Text>
                            </Box>

                            <Box as="div" marginTop={"7px"}>
                                <Text fontWeight={"semibold"} fontSize={"sm"}>Total Pages : {dataPrice.totalPages}</Text>
                            </Box>

                            <Button marginLeft={"240px"} bgColor={"#b6e1e0"} alignItems={"center"} display={"flex"} marginBottom={"5px"} onClick={handlePreviousPage} justifyContent={"center"} size={"sm"}>
                                <ArrowLeftIcon/> 
                                <Text marginLeft={"5px"} marginBottom={"3px"}>
                                    Prev
                                </Text>
                            </Button>

                            <Button onClick={handleNextPage} marginBottom={"5px"} bgColor={"#b6e1e0"} alignItems={"center"} display={"flex"} justifyContent={"center"} size={"sm"}>
                                <ArrowRightIcon/>
                                <Text marginLeft={"5px"} marginBottom={"3px"}>
                                    Next
                                </Text>
                            </Button>
                        </Flex>
                    </Box>
                </Box>
            </Box>

            {/* modal to detail data price by customers  */}
            <Modal isOpen={openEditModal} onClose={() => {setOpenEditModal(false); setRangePriceMin(0); setRangePriceMax(0); setSelectedNameFish(0);}} size={"3xl"}>
                <ModalOverlay backdropBlur={"2px"} backdropInvert={"80%"}>
                    <ModalContent>
                        <ModalHeader>Detail Data Price By Customers</ModalHeader>
                        <ModalCloseButton/>
                        
                        <ModalBody>
                            <FormControl isRequired width={"100%"}>
                                <Box width={"70%"}>
                                    <Flex flexDir={"column"} gap={5}>
                                        <Flex gap={8} flexDir={"row"}>
                                            <Box>
                                                <FormLabel>Customer Name</FormLabel>
                                                <Input type="text" marginBottom={"20px"} value={dataPriceById ? (dataPriceById.customer ? dataPriceById.customer.name : "") : ""}></Input>
                                            </Box>

                                            <Box> 
                                                <FormLabel>Fish Name</FormLabel>
                                                <Input type="text" marginBottom={"20px"} value={dataPriceById ? (dataPriceById.fish ? dataPriceById.fish.name : "") : ""}></Input>
                                            </Box>
                                        </Flex>

                                        <Box display={"flex"} flexDirection={"row"} gap={8}>
                                            <Box marginRight={"290px"}> 
                                                <FormLabel>Fix Price</FormLabel>
                                                <InputGroup>
                                                    <InputLeftAddon>Rp.</InputLeftAddon>
                                                    <Input onChange={handlePriceInput} defaultValue={dataPriceById ? (dataPriceById.price ? dataPriceById.price.toLocaleString('id-ID') : "") : ""}></Input>
                                                </InputGroup>
                                            </Box>
                                        </Box>

                                        <Box>
                                            <Button onClick={() => {setOpenEditModal(false)}} marginBottom={"3"} marginTop={"2"}>Back</Button>
                                        </Box>
                                    </Flex>
                                </Box>
                            </FormControl>
                        </ModalBody>
                    </ModalContent>
                </ModalOverlay>
            </Modal>
        </>
    )
}

export default PriceByCustomer;