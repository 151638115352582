import { Wrap, Avatar, Box, Flex, Heading, Text, Input, TableContainer, Table, Thead, Tr, Th, Tbody, Td, Button, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, FormControl, FormLabel, ModalFooter, Textarea, InputGroup, InputLeftElement, useToast} from "@chakra-ui/react";
import Sidebar from "../../components/Sidebar"
import { useEffect, useRef, useState } from "react";
import api from "../../services/api";
import { ArrowLeftIcon, ArrowRightIcon, SearchIcon } from "@chakra-ui/icons";
import { useCallback } from "react";
import debounce from "lodash.debounce";

const CustomerManajemen = () => {
    
    const { isOpen, onOpen, onClose } = useDisclosure();

    // toast
    const toast = useToast();

    // Second Modal Delete Data
    const [secondModal, setSecondModal] = useState(false);

    const closeSecondModal = () => setSecondModal(false);
    const openSecondModal = () => setSecondModal(true);

    // Third Modal Add Data Customer
    const [thirdModal, setThirdModal] = useState(false);

    const closeThirdModal = () => setThirdModal(false);
    const openThirdModal = () => setThirdModal(true);

    // state to save response data all customer
    const [dataCust, setDataCust] = useState([]);

    // state save id cust 
    const [custId, setCustId] = useState();

    // data cust by id 
    const [custById, setCustById] = useState();

    // search name bar 
    const [searchName, setSearchName] = useState("");

    // state for paginated 
    const [page, setPage] = useState(1);

    const [profile, setProfile] = useState({}); // set profile users

    // get all customer data 
    const getAllCust = async () => {
        try {
            const response = await api.get(`/api/v4/cust/pages/page?page=${page}`);
            setDataCust(response.data.payload);
        } catch (error) {
            console.log(error);
        }
    };

    // get data customers by id 
    const getCustById = async (Id) => {
        try {
            const response = await api.get(`/api/v4/cust/${Id}`);

            // delete zero first data contact
            const updatedContact = response.data.payload.contact.slice(1);
            const data = {...response.data.payload, contact: updatedContact};

            setCustById(data);
        } catch (error) {
            console.log(error)
            toast({
                title: 'Cannot Show Data',
                description: `Internal Server Error`,
                status: 'error',
                duration: 1500,
                isClosable: true,
                position: "top",
            });
        }
    }

    // get cust by name 
    const getCustByName = async (newName) => {
        try{
            const name = newName;

            const response = await api.get(`/api/v4/cust/search/name?name=${name}`);

            if (response.data.status_code === 200) {
                setDataCust(response.data.payload);
            }
        } catch (error) {
            console.log(error);
            if (error.response.data.status_code !== 200) {
                toast({
                    title: 'Cannot Show Data',
                    description: `${error.response.data.message}`,
                    status: 'error',
                    duration: 1500,
                    isClosable: true,
                    position: "top",
                });
                setDataCust([]);
            }
        }
    }

    // handle paginated 
    const handleNextPage = async() => {
        if (page !== dataCust.totalPages) {
            setPage(page +1)
        } else {
            toast({
                title: 'Warnings...!',
                description: 'You are already on the last page.',
                status: 'warning',
                duration: 1500,
                isClosable: true,
                position: 'top',
            });
        }
    };

    const handlePreviousPage = async() => {
        if (page > 1) {
            setPage(page -1)
        }
    };

    // function for set timeout on change in search bar customer manajemen
    const searchNameRef = useRef();

    const handleSearchName = useCallback(
        debounce( async (e) => {
            const newName = e.target.value;
            setSearchName(newName);

            if (searchNameRef.current) {
                searchNameRef.current.value = newName;
            }

            if (newName !== "") {
                await getCustByName(newName);
            }
        }, 1000), [],
    );

    // get data user by token 
    const getUsers = async() => {
        const token = localStorage.getItem('access_token');
        // console.log(token)
        try {
            if (token !== null) {
                const response = await api.get(`/api/v1/users/user/profile/${token}`);
                if (response.data.status_code === 200) {
                    setProfile(response.data.payload);
                }
                // console.log(response.data.payload)
            }
        } catch (error) {
            console.log("Error Fetching Data", error);
            toast({
                title: 'Error.!',
                description: `${error.response.data.message}`,
                status: 'error',
                duration: 1500,
                isClosable: true,
                position: 'top',
            }); 
        }
    }
    
    useEffect(() => {
        if (searchName === "") {
            getAllCust();
            getUsers();
        }
    }, [page, searchName]);
    
    return(
        <>
            
            <Box bgColor={"#F5F7FA"} bgSize={"cover"} height={"100vh"} display={"flex"}>
                <Sidebar/>

                <Box margin={"50px"} width={"7xl"} display={"flex"} flexDirection={"column"} marginLeft={"280px"}>
                    
                    <Box marginLeft={"10px"} marginTop={"20px"} display={"flex"} flexDir={"row"}>
                        <Box> 
                            <Heading>Customer Data</Heading>    
                        </Box>
                        
                        <Box marginLeft={"auto"}>
                            <Wrap spacing={"15px"}>
                                <Text fontWeight={"semibold"} fontSize={"xl"} marginTop={"9px"}>{profile?.full_name ? profile.full_name.split(' ').slice(0, 2).join(' ') : ''}</Text>
                                <Avatar name={profile?.full_name ?? ''}></Avatar>
                            </Wrap>
                        </Box>
                    </Box>

                    <Box padding={"30px"} display={"flex"} flexDirection={"column"} bgColor={"#FFFFFF"} borderRadius={"30px"} marginTop={"30px"} minHeight={"78vh"} >
                        <Flex flexDir={"row"} gap={860}>
                            <Box width={"190px"} alignSelf={"flex-start"}>
                                <Text marginBottom={"15px"}>Customer Name</Text>
                                <InputGroup>
                                    <InputLeftElement> 
                                        <SearchIcon/>
                                    </InputLeftElement>

                                    <Input placeholder="Name" name="name" onChange={handleSearchName} ref={searchNameRef}></Input>
                                </InputGroup>
                            </Box>

                            {/* <Box alignSelf={"flex-end"}>
                                <Button bgColor={"#4CAF4F"} borderRadius={"10px"} onClick={openThirdModal}>+ Add</Button>
                            </Box> */}
                        </Flex>

                        <Box bgColor={"whitesmoke"} boxSize={"6xl"} marginTop={"25px"} borderRadius={"20px"} marginLeft={"10px"} paddingBottom={"20px"} minHeight={"51.5vh"} maxHeight={"51.5vh"}>
                            <TableContainer>
                                <Table variant={"striped"}>
                                    <Thead>
                                        <Tr>
                                            <Th>No</Th>
                                            <Th>Name</Th>
                                            <Th>Contact</Th>
                                            <Th>Address</Th>
                                            <Th>Action</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {dataCust.data && dataCust.data.map((cust, index) => (
                                        <Tr key={cust.id}>
                                            <Td>{dataCust.currentPages != null ? (dataCust.currentPages - 1) * 5 + (index + 1) : (index + 1)}</Td>
                                            <Td>{cust.name}</Td>
                                            <Td>{cust.contact}</Td>
                                            <Td style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '300px' }}>
                                                {cust.address}
                                            </Td>
                                            <Td>
                                                <Button onClick={() => {onOpen(); getCustById(cust.id)}} size={"sm"} colorScheme="blackAlpha" borderRadius={"40px"}>Detail</Button>
                                            </Td>                                
                                        </Tr>
                                        ))}
                                    </Tbody>
                                </Table>
                            </TableContainer>
                        </Box>    

                        <Flex marginTop={"20px"} gap={"10px"} marginLeft={"15px"}>
                            <Box as="div" marginTop={"7px"} justifyContent={"flex-start"}>
                                <Text fontSize={"sm"} fontWeight={"semibold"}>Current Pages : {dataCust.currentPages}</Text>
                            </Box>

                            <Box as="div" marginTop={"7px"}>
                                <Text fontSize={"sm"} fontWeight={"semibold"}>Total Pages : {dataCust.totalPages}</Text>
                            </Box>

                            <Button marginLeft={"240px"} bgColor={"#b6e1e0"} alignItems={"center"} display={"flex"} marginBottom={"5px"} onClick={handlePreviousPage} justifyContent={"center"} size={"sm"}>
                                <ArrowLeftIcon/> 
                                <Text marginLeft={"5px"} marginBottom={"3px"}>
                                    Prev
                                </Text>
                            </Button>

                            <Button onClick={handleNextPage} marginBottom={"5px"} bgColor={"#b6e1e0"} alignItems={"center"} display={"flex"} justifyContent={"center"} size={"sm"}>
                                <ArrowRightIcon/>
                                <Text marginLeft={"5px"} marginBottom={"3px"}>
                                    Next
                                </Text>
                            </Button>
                        </Flex>

                    </Box>
                </Box>
            </Box>   
            

            {/* Modal Detail  */}

            <Modal isOpen={isOpen} onClose={onClose} size={"2xl"}>
                <ModalOverlay backdropBlur={"2px"} backdropInvert={"80%"}/>

                <ModalContent>
                    <ModalHeader>Detail Data Customer</ModalHeader>
                    <ModalCloseButton/>

                    <ModalBody >
                        {custById && (
                            <form key={custById.id}>
                        <FormControl isRequired width={"100%"}>
                                <Box width={"55%"}>
                                    <FormLabel>Id</FormLabel>
                                    <Input placeholder="Id" type="text" name="id" value={custById.id} readOnly marginBottom={"20px"}></Input>

                                    <FormLabel>Name</FormLabel>
                                    <Input placeholder="Name" type="text" name="name" defaultValue={custById.name} marginBottom={"20px"}></Input>

                                    <FormLabel>Contact</FormLabel>
                                    <InputGroup>
                                        <InputLeftElement color={"GrayText"}>+62</InputLeftElement>
                                        <Input placeholder="81215267243" type="text" name="contact" defaultValue={custById.contact.replace(/^62/, '')} marginBottom={"20px"}></Input>
                                    </InputGroup>

                                    <FormLabel>Address</FormLabel>
                                    <Textarea placeholder="Address" marginBottom={"20px"} name="address" defaultValue={custById.address} resize={"horizontal"}></Textarea>
                                    

                                    <Button marginTop={"15px"} marginBottom={"15px"} onClick={onClose}>Back</Button>
                                </Box>
                        </FormControl>
                            </form>
                        )}
                    </ModalBody>
                </ModalContent>
            </Modal>

        </>
    )
};

export default CustomerManajemen;