import { Wrap, Avatar, Box, Flex, Heading, Select, Text, Input, TableContainer, Table, Thead, Tr, Th, Tbody, Td, Button, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, FormControl, FormLabel, ModalFooter, useToast, Textarea, InputGroup, InputLeftAddon} from "@chakra-ui/react";
import SidebarSuperAdmin from "../../components/SidebarSuperAdmin";
import { useState } from "react";
import api from "../../services/api";
import { useEffect } from "react";
import { ArrowLeftIcon, ArrowRightIcon } from "@chakra-ui/icons";
import { debounce } from "lodash";
import * as XLSX from 'xlsx';
import { format } from "date-fns";
// import { writeFile } from 'xlsx-style';

const SalesInformation = () => {
    
    const { isOpen, onOpen, onClose } = useDisclosure();

    const toast = useToast();

    // State
    const [secondModal, setSecondModal] = useState(false); //Second Modal Delete Data
    const [page, setPage] = useState(1);// state to paginated
    const [dataRecaps, setDataRecaps] = useState([]); // state to save data all recaps
    const [detailsModal, setDetailsModal] = useState(false); //modal details open and close
    const [dataRecapsById, setDataRecapsById] = useState(); //state to save data recaps get by id
    const [newStatus, setNewStatus] = useState(""); //state to save new status after update
    const [newNotes, setNewNotes] = useState(""); // state to save after new notes update
    const [idRecap, setIdRecap] = useState(0); // state to save id recap 
    const [newPaymentRemaining, setNewPaymentRemaining] = useState(0); //set state payment remaining 
    const [profile, setProfile] = useState({}); //state save profile users
    const [omsetModal, setOmsetModal] = useState(false); // set modal get omset 
    const [loading, setLoading] = useState(false); //set loading for edit 
    const [newCreatedAt, setNewCreatedAt] = useState(new Date()); //set state for created at

    // state for filtering data
    const [filters, setFilters] = useState({
        page: 1,
        customer: '',
        startDate: '',
        endDate: '',
        status: ''
    });

    // Modal Delete
    const closeSecondModal = () => setSecondModal(false);
    const openSecondModal = () => setSecondModal(true);

    // modal details 
    const closeDetailsModal = () => {
        setDetailsModal(false); 
        setNewStatus(""); 
        setNewNotes(""); 
        setNewPaymentRemaining(0);
        setDataRecapsById();
    };

    const openDetailsModal = () => setDetailsModal(true);

    // Modal Get Omset 
    const closeOmsetModal = () => setOmsetModal(false);
    const openOmsetModal = () => setOmsetModal(true);

    // modal edit close
    const onCloseEdit = () => {
        setDataRecapsById();
        setNewStatus(""); 
        setNewNotes(""); 
        setNewPaymentRemaining(0);
        onClose();
    };
    
    // get all recaps 
    const getAllRecaps = async() => {
        try {
            const response = await api.get(`/api/v6/recap?page=${page}`);
            setDataRecaps(response.data.payload);
            //console.log(response.data.payload.data)
        } catch (error) {
            console.log("Error Fetching Data", error);
            toast({
                title: 'Error.!',
                description: `${error.response.data.message}`,
                status: 'error',
                duration: 1500,
                isClosable: true,
                position: 'top',
            });
        }
    };
    
    // get details recap by id 
    const getRecapById = async(id)=> {
        try {
            const response = await api.get(`/api/v6/recap/${id}`);
            setDataRecapsById(response.data.payload[0]);
            setNewStatus(response.data.payload[0].status)
            setNewPaymentRemaining(response.data.payload[0].remainingAmount)
            setNewNotes(response.data.payload[0].notes)
            setNewCreatedAt(response.data.payload[0].createdAt)
            // console.log(response.data.payload[0])
        } catch (error) {
            console.log("Error Fetching Data", error);
            toast({
                title: 'Error.!',
                description: `${error.response.data.message}`,
                status: 'error',
                duration: 1500,
                isClosable: true,
                position: 'top',
            });
        }
    };

    // get id recap before delete 
    const handleDeleteGetId = (id) => {
        setIdRecap(id)
    };

    // delete recap by id 
    const deleteRecapById = async() => {
        try {
            const response = await api.delete(`/api/v6/recap/${idRecap}`);
            if (response.data.status_code === 200) {

                toast({
                    title: 'Delete Data Successfully',
                    description: `${response.data.message}`,
                    status: 'success',
                    duration: 1500,
                    isClosable: true,
                    position: 'top',
                });
                getRecapByFilters(); 
            }
        } catch (error) {
            console.log("Error Fetching Data", error);
            toast({
                title: 'Error.!',
                description: `${error.response.data.message}`,
                status: 'error',
                duration: 1500,
                isClosable: true,
                position: 'top',
            });
        }
    }

    // handle change date time 
    const handleChangeDate = (e) => {
        setNewCreatedAt(new Date(e.target.value));
    };

    // handle change status and set state
    const handleChangeStatus = (e) => {
        setNewStatus(e.target.value);
    };
    
    // handle change notes and set state
    const handleChangeNotes = debounce((e) => {
        setNewNotes(e.target.value)
    }, 100);

    // handle change payment remaining 
    const handleChangePaymentRemaining = debounce((e) => {
        const paymentRemainigs = parseInt(e.target.value);
        // console.log(paymentRemainigs)

        if (isNaN(paymentRemainigs)) {
            return setNewPaymentRemaining(0)
        }else{
            setNewPaymentRemaining(paymentRemainigs)
        }
    }, 100);
    console.log(newCreatedAt)

    // function for update data recap)
    const updateRecap = async() => {
        setLoading(true);

        // date operations
        const formattedDate = format(newCreatedAt, 'yyyy-MM-dd HH:mm:ss');
        // console.log(formattedDate)

        const newRecap = {
            customerId: dataRecapsById.customerId,
            fishId: dataRecapsById.fishId,
            usersId: dataRecapsById.usersId,
            priceByCustomersId: dataRecapsById.priceByCustomersId,
            price: dataRecapsById.price,
            total_product: dataRecapsById.total_product,
            total_price: dataRecapsById.total_price,
            status: newStatus,
            remainingAmount: newPaymentRemaining,
            notes: newNotes,
            createdAt: formattedDate
        }

        // console.log("new recap" + newRecap.remainingAmount)

        try {
            const response = await api.put(`/api/v6/recap/${dataRecapsById.id}`, newRecap);
            if (response.data.status_code === 200) {
                toast({
                    title: 'Data created.',
                    description: `${response.data.message}`,
                    status: 'success',
                    duration: 1500,
                    isClosable: true,
                    position: "top",
                })
                onCloseEdit();

                getRecapByFilters();
                // setTimeout(() => {
                //     window.location.reload();
                // }, 1500);
            }
        } catch (error) {
            console.log("Error Fetching data", error);
            if (error.response.data.status_code !== 200) {
                toast({
                    title: 'Error',
                    description: `${error.response.data.message}`,
                    status: 'error',
                    duration: 1500,
                    isClosable: true,
                    position: "top",
                });
            }
        }finally{
            setLoading(false);
        }
    };

    // function for get value and set state filtering
    const handleInputChange = debounce((e) => {
        const {name, value} = e.target;
        const newFilters = { ...filters, [name]: value};
        setFilters(newFilters)
    }, 500);

    // console.log(filters)

    // function get api filters
    const getRecapByFilters = async() => {

        let url = '/api/v6/recap?';

        if (filters.page !== "") {
            url += `page=${filters.page}`;
        }
        if (filters.customer !== "") {
            url += `&customer=${filters.customer}`;
        }
        if (filters.startDate !== "") {
            url += `&startDate=${filters.startDate}`;
        }
        if (filters.endDate !== "") {
            url += `&endDate=${filters.endDate}`
        }
        if (filters.status !== "") {
            url += `&status=${filters.status}`
        }
        // console.log(url)
        try {
            const response = await api.get(url);
            if (response.data.status_code === 200) {
                setDataRecaps(response.data.payload)
                // console.log(response.data.payload)
            }
        } catch (error) {
            console.log("Error Fetching data", error);
            if (error.response.data.status_code !== 200) {
                toast({
                    title: 'Error',
                    description: `${error.response.data.message}`,
                    status: 'error',
                    duration: 1500,
                    isClosable: true,
                    position: "top",
                });
            }
            setDataRecaps([])
        }
    }

    // function for paginated next end prev
    const handlePrevPage = () => {
        if (page > 1) {
            setPage(page - 1)
            // for filters data
            const newFilters = { ...filters, page: page - 1};
            setFilters(newFilters)
        };
        // for all recap not filtering
        if (filters.customer === "" && filters.date === "" && filters.status === "") {
            getAllRecaps();
        }
    };

    const handleNextPage = () => {
        if (page !== dataRecaps.totalPages) {
            setPage(page + 1)
            // for filters data 
            const newFilters = { ...filters, page: page + 1};
            setFilters(newFilters)
        }else{
            toast({
                title: 'Warnings...!',
                description: 'You are already on the last page.',
                status: 'warning',
                duration: 1500,
                isClosable: true,
                position: 'top',
            });
        }
        // for all recap not filtering
        if (filters.customer === "" && filters.startDate === "" && filters.endDate === "" && filters.status === "") {
            getAllRecaps();
        }
    };

    // function for download excel
    const exportToExcel = async () => {
        let allData = [];
        let currentPage = 1;
        let totalPages = 1; // mengasumsikan ada minimal 1 halaman
        let total_Omset = 0; // variabnle untuk total omset 
        let total_Remaining_Amount = 0; // variable untuk menghitung kekurangan uang
        let total_Paid = 0; // variable untuk menghitung total semua yang sudah dibayar 
      
        // Loop sampai currentPage melebihi totalPages
        while (currentPage <= totalPages) {
          let url = `/api/v6/recap?page=${currentPage}`;
      
          // Tambahkan filter ke URL jika ada filter baru yang berubah
          if (filters.customer !== "") {
            url += `&customer=${filters.customer}`;
          }
          if (filters.startDate !== "") {
            url += `&startDate=${filters.startDate}`;
          }
          if (filters.endDate !== "") {
            url += `&endDate=${filters.endDate}`;
          }
          if (filters.status !== "") {
            url += `&status=${filters.status}`;
          }
      
          try {
            const response = await api.get(url);
            if (response.data.status_code === 200) {
              const result = response.data.payload;
              allData = [...allData, ...result.data];
              totalPages = result.totalPages;
              currentPage++;
            }
          } catch (error) {
            console.log("Error Fetching data", error);
            if (error.response.data.status_code !== 200) {
              toast({
                title: 'Error',
                description: `${error.response.data.message}`,
                status: 'error',
                duration: 1500,
                isClosable: true,
                position: "top",
              });
            }
            break; // Berhenti jika ada kesalahan
          }
        }

        // Mengurutkan data berdasarkan createdAt secara ascending
        allData.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
      
        // Hitung total omset
        total_Omset = allData.reduce((total, item) => total + item.total_price, 0);

        // Hitung Not Yet Paid
        total_Remaining_Amount = allData.reduce((total, item) => total + item.remainingAmount, 0);

        // Hitung Omset bersih 
        total_Paid = total_Omset - total_Remaining_Amount;

        // Proses data dan menyimpan ke Excel
        const formattedData = allData.map((item,index) => ({
            NO: index+1,
            Date: new Date(item.createdAt).toLocaleDateString('id-ID'),
            Customer: item.customer.name,
            Fish: item.fish.name,
            Total_Fish: item.total_product,
            Price: item.price.toLocaleString('id-ID'),
            Total_Price: item.total_price.toLocaleString('id-ID'),
            Status: item.status,
            Remaining_Amount: item.remainingAmount.toLocaleString('id-ID'),
            Notes: item.notes,
        }));
        

        // Menambahkan space kosong 
        formattedData.push({
            NO: "",
            Date: "",
            Customer: "",
            Fish: "",
            Total_Fish: "",
            Price: "",
            Total_Price: "",
            Status: "",
            Remaining_Amount: "",
            Notes: "",
        });

        // menambahkan total omset
        formattedData.push({
            NO: "",
            Date: "",
            Customer: "",
            Fish: "",
            Total_Fish: "",
            Price: "",
            Total_Price: "",
            Status: "",
            Remaining_Amount: "",
            Notes: "Total Omset",
            "": total_Omset.toLocaleString('id-ID'),
        });

        // menambahkan kurangan total bayar 
        formattedData.push({
            NO: "",
            Date: "",
            Customer: "",
            Fish: "",
            Total_Fish: "",
            Price: "",
            Total_Price: "",
            Status: "",
            Remaining_Amount: "",
            Notes: "Total Not Paid",
            "": total_Remaining_Amount.toLocaleString('id-ID'),
        });

        // menambahkan total yang sudah dibayarkan
        formattedData.push({
            NO: "",
            Date: "",
            Customer: "",
            Fish: "",
            Total_Fish: "",
            Price: "",
            Total_Price: "",
            Status: "",
            Remaining_Amount: "",
            Notes: "Total Paid",
            "": total_Paid.toLocaleString('id-ID'),
        });
      
        // Membuat sheet Excel
    const ws = XLSX.utils.json_to_sheet(formattedData);

    // Set warna latar belakang berdasarkan status
    const range = XLSX.utils.decode_range(ws['!ref']);
    for (let R = range.s.r + 1; R <= range.e.r; ++R) {
        const cellRef = XLSX.utils.encode_cell({ r: R, c: 7 }); // Kolom Status adalah kolom ke-7 (0-based)
        const cellValue = ws[cellRef] ? ws[cellRef].v : "";
        if (cellValue === "Not Yet Paid" || cellValue === "Not Yet Paid Off") {
            const cell = ws[cellRef];
            cell.s = {
                fill: {
                    patternType: "solid",
                    fgColor: { rgb: "FFFF0000" } // Warna merah untuk status 'Not Yet Paid' dan 'Not Yet Paid Off'
                }
            };
        }
    }

    // Membuat workbook Excel dan menyimpan file
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "data.xlsx");
};

    //   get users by token 
    const getUsers = async() => {
        const token = localStorage.getItem('access_token');
        // console.log(token)
        try {
            if (token !== null) {
                const response = await api.get(`/api/v1/users/user/profile/${token}`);
                if (response.data.status_code === 200) {
                    setProfile(response.data.payload);
                }
                // console.log(response.data.payload)
            }
        } catch (error) {
            console.log("Error Fetching Data", error);
            toast({
                title: 'Error.!',
                description: `${error.response.data.message}`,
                status: 'error',
                duration: 1500,
                isClosable: true,
                position: 'top',
            }); 
        }
    }

    // state save get omset
    const [omsetData, setOmsetData] = useState(null);

    // function get omset for modal get omset
    const getOmset = async() => {
        let allData = [];
        let currentPage = 1;
        let totalPages = 1; // mengasumsikan ada minimal 1 halaman
        let total_Omset = 0; // variabnle untuk total omset 
        let total_Remaining_Amount = 0; // variable untuk menghitung kekurangan uang
        let total_Paid = 0; // variable untuk menghitung total semua yang sudah dibayar 

        while (currentPage <= totalPages) {
            let url = `/api/v6/recap?page=${currentPage}`;
      
            // Tambahkan filter ke URL jika ada filter baru yang berubah
            if (filters.startDate !== "") {
            url += `&startDate=${filters.startDate}`;
            }
            if (filters.endDate !== "") {
            url += `&endDate=${filters.endDate}`;
            }
            if (filters.customer !== "") {
                url += `&customer=${filters.customer}`;
            }
            if (filters.status !== "") {
                url += `&status=${filters.status}`;
            }

            try {
                const response = await api.get(url);
                if (response.data.status_code === 200) {
                const result = response.data.payload;
                allData = [...allData, ...result.data];
                totalPages = result.totalPages;
                currentPage++;
                }
            } catch (error) {
                console.log("Error Fetching data", error);
                if (error.response.data.status_code !== 200) {
                toast({
                    title: 'Error',
                    description: `${error.response.data.message}`,
                    status: 'error',
                    duration: 1500,
                    isClosable: true,
                    position: "top",
                });
                }
                break;
            }
            }

            // Hitung total omset
            total_Omset = allData.reduce((total, item) => total + item.total_price, 0);

            // Hitung Not Yet Paid
            total_Remaining_Amount = allData.reduce((total, item) => total + item.remainingAmount, 0);

            // Hitung Omset bersih 
            total_Paid = total_Omset - total_Remaining_Amount;

            setOmsetData({
                total_Omset: total_Omset,
                total_Remaining_Amount: total_Remaining_Amount,
                total_Paid: total_Paid,
            });
    }

    useEffect(() => {
        if (filters.customer === "" && filters.startDate === "" && filters.endDate === "" && filters.status === "") {
            getAllRecaps();
        }
        getRecapByFilters();
        getUsers();
    }, [page, filters]);

    console.log(dataRecaps.data)
    
    return(
        <>
            <Box bgColor={"#F5F7FA"} bgSize={"cover"} height={"100vh"} display={"flex"}>
                <SidebarSuperAdmin/>

                <Box margin={"50px"} width={"100%"} display={"flex"} flexDirection={"column"} marginLeft={"280px"}>
                    
                    <Box marginLeft={"10px"} marginTop={"20px"} display={"flex"} flexDir={"row"} width={"100%"}>
                        <Box> 
                            <Heading>Sales Information</Heading>    
                        </Box>
                        
                        <Box marginLeft={"auto"}>
                            <Wrap spacing={"15px"}>
                                <Text fontWeight={"semibold"} fontSize={"xl"} marginTop={"9px"}>{profile?.full_name ? profile.full_name.split(' ').slice(0, 2).join(' ') : ''}</Text>
                                <Avatar name={profile?.full_name ?? ''}></Avatar>
                            </Wrap>
                        </Box>
                    </Box>

                    <Box padding={"30px"} display={"flex"} flexDirection={"column"} bgColor={"#FFFFFF"} borderRadius={"30px"} marginTop={"30px"} minHeight={"78vh"} maxHeight={"78vh"} width={"100%"}>
                        <Flex flexDir={"row"} gap={5} marginTop={"-1"} width={"100%"}>
                            <Flex flexDir={"row"} gap={2}>
                                <Box width={"155px"}>
                                    <Text marginBottom={"15px"}>Customer</Text>
                                    <Input type="text" placeholder="Name" name="customer" defaultValue={filters.customer} onChange={handleInputChange}></Input>
                                </Box>

                                <Box width={"155px"}>
                                    <Text marginBottom={"15px"}>Status</Text>
                                    <Select variant={"outline"} placeholder="Status" name="status" defaultValue={filters.status} onChange={handleInputChange}>
                                        <option value={"Paid"}>Paid</option>
                                        <option value={"Not Yet Paid"}>Not Yet Paid</option>
                                        <option value={"Not Yet Paid Off"}>Not Yet Paid Off</option>
                                    </Select>
                                </Box>

                                <Box width={"155px"}>
                                    <Text marginBottom={"15px"}>Start Date</Text>
                                    <Input type="date" placeholder="Date" name="startDate" defaultValue={filters.startDate} onChange={handleInputChange}></Input> 
                                </Box>

                                <Box width={"155px"}>
                                    <Text marginBottom={"15px"}>End Date</Text>
                                    <Input type="date" placeholder="Date" name="endDate" defaultValue={filters.endDate} onChange={handleInputChange}></Input>
                                </Box>
                            </Flex>    

                            <Flex flexDir={"row"} gap={2}>
                                <Box marginLeft={"120px"}>
                                    <Button size={"md"} borderRadius={"10px"} marginTop={"38px"} bgColor={"#fd5c63"} onClick={() => {openOmsetModal();}}> Get Omset </Button>
                                </Box>
                                <Box>
                                    <Button size={"md"} borderRadius={"10px"} marginTop={"38px"} bgColor={"#6895D2"} onClick={exportToExcel}>Download Exel</Button>
                                </Box>

                                <Box>
                                    <Button size={"md"} bgColor={"#4CAF4F"} borderRadius={"10px"} marginTop={"38px"} onClick={() => {window.location.href= '/backoffice/addDataSale'}}>+ Add</Button>
                                </Box>
                            </Flex>
                        </Flex>

                        <Box bgColor={"whitesmoke"} width={"100%"} marginTop={"25px"} borderRadius={"20px"} marginLeft={"10px"} paddingBottom={"17px"} minHeight={"52.5vh"} maxHeight={"52.5vh"}>
                            <TableContainer>
                                <Table variant={"striped"}>
                                    <Thead>
                                        <Tr>
                                            <Th>No</Th>
                                            <Th>Date</Th>
                                            <Th>Customer</Th>
                                            <Th>Fish Name</Th>
                                            <Th>Total Fish</Th>
                                            <Th>Total Price</Th>
                                            <Th>Status</Th>
                                            <Th>Action</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {dataRecaps.data && dataRecaps.data.map((recap, index) => (
                                            <Tr>
                                                <Td>{(dataRecaps.currentPages - 1) * 5 + (index + 1)}</Td>
                                                <Td>{new Date(recap.createdAt).toLocaleDateString('id-ID')}</Td>
                                                <Td>{recap.customer.name}</Td>
                                                <Td>{recap.fish.name}</Td>
                                                <Td>{recap.total_product}Kg</Td>                   
                                                <Td>{`Rp. ${recap.total_price.toLocaleString('id-ID')}`}</Td>
                                                <Td>{recap.status}</Td>  
                                                <Td>
                                                    <Flex gap={"2"}>
                                                        <Button onClick={() => {openDetailsModal(); getRecapById(recap.id)}} size={"sm"} colorScheme="blackAlpha" borderRadius={"40px"}>Detail</Button>
                                                        <Button onClick={() => {onOpen(); getRecapById(recap.id)}} size={"sm"} colorScheme="blue" borderRadius={"40px"}>Edit</Button>
                                                        <Button onClick={() => {openSecondModal(); handleDeleteGetId(recap.id)}} size={"sm"} colorScheme="red" borderRadius={"40px"}>Delete</Button>
                                                    </Flex>    
                                                </Td>                                
                                            </Tr>
                                        ))}
                                    </Tbody>
                                </Table>
                            </TableContainer>
                        </Box>   

                        <Flex marginTop={"15px"} gap={"10px"} marginLeft={"15px"} width={"100%"}>
                            <Box as="div" marginTop={"7px"} justifyContent={"flex-start"}>
                                <Text fontWeight={"semibold"} fontSize={"sm"}>Current Pages : {dataRecaps.currentPages}</Text>
                            </Box>

                            <Box as="div" marginTop={"7px"}>
                                <Text fontWeight={"semibold"} fontSize={"sm"}>Total Pages : {dataRecaps.totalPages}</Text>
                            </Box>

                            <Button onClick={handlePrevPage} marginLeft={"250px"} bgColor={"#b6e1e0"} alignItems={"center"} display={"flex"} marginBottom={"5px"} justifyContent={"center"} size={"sm"}>
                                <ArrowLeftIcon/> 
                                <Text marginLeft={"5px"} marginBottom={"3px"}>
                                    Prev
                                </Text>
                            </Button>

                            <Button onClick={handleNextPage} marginBottom={"5px"} bgColor={"#b6e1e0"} alignItems={"center"} display={"flex"} justifyContent={"center"} size={"sm"}>
                                <ArrowRightIcon/>
                                <Text marginLeft={"5px"} marginBottom={"3px"}>
                                    Next
                                </Text>
                            </Button>
                        </Flex>

                    </Box>
                </Box>
            </Box> 

            {/* Modal Details  */}

            <Modal isOpen={detailsModal} onClose={closeDetailsModal} size={"5xl"}>
                <ModalOverlay backdropBlur={"2px"} backdropInvert={"80%"}/>

                <ModalContent>
                    <ModalHeader>Details Data Recap</ModalHeader>
                    <ModalCloseButton/>

                    <ModalBody >
                        <FormControl width={"100%"}>
                            <Flex flexDir={"row"} gap={10}>
                                <Box width={"40%"}>
                                    <FormLabel>Customer Name</FormLabel>
                                    <Input type="text" marginBottom={"20px"} value={dataRecapsById ? dataRecapsById.customer.name : ""}></Input>

                                    <FormLabel>Fish Name</FormLabel>
                                    <Input type="text" marginBottom={"20px"} value={dataRecapsById ? dataRecapsById.fish.name : ""}></Input>

                                    <FormLabel>Price Fish</FormLabel>
                                    <InputGroup>
                                        <InputLeftAddon>Rp</InputLeftAddon>
                                        <Input type="text" marginBottom={"20px"} value={dataRecapsById ? dataRecapsById.price.toLocaleString("id-ID") : ""}></Input>
                                    </InputGroup>

                                    <FormLabel>Total Fish 'Kg'</FormLabel>
                                    <InputGroup>
                                        <InputLeftAddon>Kg</InputLeftAddon>
                                        <Input type="number" marginBottom={"20px"} value={dataRecapsById ? dataRecapsById.total_product : ""}></Input>  
                                    </InputGroup>

                                    <FormLabel>Total Price</FormLabel>
                                    <InputGroup>
                                        <InputLeftAddon>Rp</InputLeftAddon>
                                        <Input type="text" marginBottom={"20px"} value={dataRecapsById ? "Rp. " + dataRecapsById.total_price.toLocaleString("id-ID") : ""}></Input>
                                    </InputGroup>
                                
                                    <Button marginTop={"15px"} marginBottom={"15px"} onClick={closeDetailsModal}> 
                                        <ArrowLeftIcon boxSize={"3.5"} marginRight={"5px"} marginTop={"3px"}/>
                                        Back
                                    </Button>
                                </Box>

                                <Box width={"40%"}>
                                    
                                    <FormLabel>Status</FormLabel>
                                    <Input marginBottom={"20px"} value={dataRecapsById ? dataRecapsById.status : ""}></Input>

                                    <FormLabel>Payment Remaining</FormLabel>
                                    <InputGroup>
                                        <InputLeftAddon>Rp</InputLeftAddon>
                                        <Input marginBottom={"20px"} value={dataRecapsById ? dataRecapsById.remainingAmount.toLocaleString("id-ID") : ""} ></Input>
                                    </InputGroup>

                                    <FormLabel>Data Created At</FormLabel>
                                    <Input marginBottom={"20px"} value={dataRecapsById ? dataRecapsById.createdAt : ""}></Input>

                                    <FormLabel>Data Updated At</FormLabel>
                                    <Input marginBottom={"20px"} value={dataRecapsById ? dataRecapsById.updatedAt : ""}></Input>

                                    <FormLabel>Notes</FormLabel>
                                    <Textarea marginBottom={"20px"} value={dataRecapsById ? dataRecapsById.notes : ""}></Textarea>
                                </Box>
                            </Flex>               
                        </FormControl>
                    </ModalBody>
                </ModalContent>
            </Modal>
            

            {/* Modal Edit  */}

            <Modal isOpen={isOpen} onClose={onCloseEdit} size={"5xl"}>
                <ModalOverlay backdropBlur={"2px"} backdropInvert={"80%"}/>

                <ModalContent>
                    <ModalHeader>Edit Data</ModalHeader>
                    <ModalCloseButton/>

                    <ModalBody >
                        <FormControl isRequired width={"100%"}>
                            <Flex flexDir={"row"} gap={10}>
                            <Box width={"40%"}>
                                
                                    <FormLabel>Customer Name</FormLabel>
                                    <Input type="text" marginBottom={"20px"} value={dataRecapsById ? dataRecapsById.customer.name : ""}></Input>

                                    <FormLabel>Fish Name</FormLabel>
                                    <Input type="text" marginBottom={"20px"} value={dataRecapsById ? dataRecapsById.fish.name : ""}></Input>

                                    <FormLabel>Price Fish</FormLabel>
                                    <InputGroup>
                                        <InputLeftAddon>Rp</InputLeftAddon>
                                        <Input type="text" marginBottom={"20px"} value={dataRecapsById ? dataRecapsById.price.toLocaleString("id-ID") : ""}></Input>
                                    </InputGroup>

                                    <FormLabel>Total Fish 'Kg'</FormLabel>
                                    <InputGroup>
                                        <InputLeftAddon>Kg</InputLeftAddon>
                                        <Input type="number" marginBottom={"20px"} value={dataRecapsById ? dataRecapsById.total_product : ""}></Input>  
                                    </InputGroup>

                                    <FormLabel>Total Price</FormLabel>
                                    <InputGroup>
                                        <InputLeftAddon>Rp</InputLeftAddon>
                                        <Input type="text" marginBottom={"20px"} value={dataRecapsById ? "Rp. " + dataRecapsById.total_price.toLocaleString("id-ID") : ""}></Input>
                                    </InputGroup>
                                
                                    <Button marginTop={"15px"} marginBottom={"15px"} onClick={updateRecap} isLoading={loading} loadingText="Editing..." colorScheme="green"> 
                                        Edit
                                    </Button>
                                </Box>

                                <Box width={"40%"}>
                                    
                                    <FormLabel>Data Created At</FormLabel>
                                    <Input marginBottom={"20px"} defaultValue={dataRecapsById ? dataRecapsById.createdAt : ""} type="datetime-local" onChange={handleChangeDate}></Input>

                                    <FormLabel>Data Updated At</FormLabel>
                                    <Input marginBottom={"20px"} value={dataRecapsById ? dataRecapsById.updatedAt : ""}></Input>

                                    <FormLabel>Status</FormLabel>
                                    <Select marginBottom={"20px"} defaultValue={dataRecapsById ? dataRecapsById.status : ""} onChange={handleChangeStatus}>
                                        <option>{dataRecapsById ? dataRecapsById.status : ""}</option>
                                        <option>---------------------</option>
                                        <option>Paid</option>
                                        <option>Not Yet Paid</option>
                                        <option>Not Yet Paid Off</option>
                                    </Select>

                                    <FormLabel>Payment Remaining</FormLabel>
                                    <InputGroup>
                                        <InputLeftAddon>Rp</InputLeftAddon>
                                        <Input marginBottom={"20px"} defaultValue={dataRecapsById ? dataRecapsById.remainingAmount.toLocaleString("id-ID") : ""} onChange={handleChangePaymentRemaining}></Input>
                                    </InputGroup>

                                    <FormLabel>Notes</FormLabel>
                                    <Textarea marginBottom={"20px"} defaultValue={dataRecapsById ? dataRecapsById.notes : ""} onChange={handleChangeNotes}></Textarea>

                                    <Text as="mark" bgColor={"InfoBackground"} textColor={"black"}> Only Updates Status, Date, Payment Remaining, and Notes.! </Text>
                                </Box>
                            </Flex>               
                        </FormControl>
                    </ModalBody>
                </ModalContent>
            </Modal>

            {/* Second modal Delete */}
            <Modal isOpen={secondModal} onClose={closeSecondModal} size="sm">
                <ModalOverlay />
                <ModalContent>
                <ModalHeader>Are you sure ?</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    Are you sure to delete this data ?
                </ModalBody>
                <ModalFooter>
                    <Flex gap={"2"}>
                        <Button colorScheme="blue" onClick={closeSecondModal}>No</Button>
                        <Button colorScheme="green" onClick={() => {closeSecondModal(); deleteRecapById();}}>Yes</Button>
                    </Flex>
                </ModalFooter>
                </ModalContent>
            </Modal>

            {/* Modal Get Omset */}
            <Modal isOpen={omsetModal} onClose={closeOmsetModal} size={"2xl"} >
                <ModalOverlay backdropBlur={"2px"} backdropInvert={"80%"}/>

                <ModalContent minHeight={"250px"}>
                    <ModalHeader>Get Omset By Range Data</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                       <Box marginBottom={"20px"}>
                            <Box display={"flex"} flexDir={"column"} gap={"20px"}>
                                <Box display={"flex"} flexDir={"row"} gap={"70px"}>
                                    <Box>
                                        <Text>Start Date</Text>
                                        <Input marginTop={"20px"} type="date" placeholder="Date" name="startDate" defaultValue={filters.startDate} onChange={handleInputChange}></Input> 
                                    </Box>
                                    
                                    <Box>
                                        <Text>End Date</Text>
                                        <Input marginTop={"20px"} type="date" placeholder="Date" name="endDate" defaultValue={filters.endDate} onChange={handleInputChange}></Input>
                                    </Box>    
                                </Box>

                                <Box>
                                    <Button onClick={() => {getOmset()}}>Get Omset</Button>
                                </Box>

                                <Box display={"flex"} flexDir={"column"} gap={"10px"}>
                                    <Box display={"flex"} flexDir={"row"} gap={"10px"}>
                                        <Text width={"20%"}>Total Omset</Text>
                                        <Text>:</Text>
                                        <Input type="text" value={omsetData !== null ? "Rp. " + omsetData.total_Omset.toLocaleString("id-ID") : 0} width={"40%"}></Input>
                                    </Box>

                                    <Box display={"flex"} flexDir={"row"} gap={"10px"}>
                                        <Text width={"20%"}>Total Not Paid</Text>
                                        <Text>:</Text>
                                        <Input type="text" value={omsetData ? "Rp. " + omsetData.total_Remaining_Amount.toLocaleString("id-ID") : 0} width={"40%"}></Input>
                                    </Box>

                                    <Box display={"flex"} flexDir={"row"} gap={"10px"}>
                                        <Text width={"20%"}>Total Paid</Text>
                                        <Text>:</Text>
                                        <Input type="text" value={omsetData ? "Rp. " + omsetData.total_Paid.toLocaleString("id-ID") : 0} width={"40%"}></Input>
                                    </Box>
                                </Box>
                            </Box>
                       </Box>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
};

export default SalesInformation;