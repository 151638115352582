import { Box, Button, Center, FormControl, FormLabel, Heading, Input, Text, useToast } from "@chakra-ui/react";
import { useState } from "react";
import axios from "axios";
import api from "../services/api";

const ForgotPassword = () => {

    const toast = useToast();

    // show and hide password 
    const [showPassword] = useState(false);

    //state
    const [newData, setNewData] = useState({
        username: "",
        newPassword: "",
        repeatNewPassword: "",
    });

    // functions update input change
    const handleInputChanges = (event) => {
        const {name,value} = event.target;
        setNewData({
            ...newData,
            [name]: value,
        });
    };

    // functions update password 
    const updatePassword = async() => {
        try {
            const response = await api.put('/api/v2/auth/forgotPassword', newData);

            if (response.data.status_code === 200) {
                setTimeout(() => {
                   window.location.href = '/login' 
                }, 2000);

                toast({
                    title: 'Updated Data Succesfull!',
                    description: `${response.data.message}`,
                    status: 'success',
                    duration: 2000,
                    isClosable: true,
                    position: 'top',
                });
            }
        } catch (error) {
            console.log(error);
            toast({
                title: 'Error..!',
                status: 'error',
                description: `${error.response.data.message}`,
                duration: 2000,
                isClosable: true,
                position: 'top'
            });
        }
    }

    // console.log(newData)
    return(
        <>
            <Box height={"100vh"} bgColor={"#F5F7FA"} bgSize={"cover"}>
                <Center paddingTop={[null, "7%"]}>
                    <Box bgColor={"#FFFFFF"} padding={"50px"} display={"flex"} justifyContent={"space-between"} flexDir={"column"}>
                        <Heading textAlign={"center"} fontSize={"2xl"} color={"#00A551"}>Set New Password.!</Heading>
                        <Text marginTop={"10px"} color={"#474749"} fontWeight={"normal"} textAlign={"center"}>Please enter your new password and make sure you <br/>
                        remember the password</Text>

                        <FormControl marginTop={"30px"}>
                            <FormLabel color={"#7F7E83"} fontWeight={"normal"}>
                                Username
                            </FormLabel>
                            <Input type="text" name="username" value={newData.username} onChange={handleInputChanges}></Input>

                            <FormLabel marginTop={"20px"} color={"#7F7E83"} fontWeight={"normal"}>
                                New Password
                            </FormLabel>
                            <Input type={showPassword ? "text" : "password"} name="newPassword" value={newData.newPassword} onChange={handleInputChanges}></Input>

                            <FormLabel marginTop={"20px"} color={"#7F7E83"} fontWeight={"normal"}>
                                Confirm New Password
                            </FormLabel>
                            <Input type={showPassword ? "text" : "password"} name="repeatNewPassword" value={newData.repeatNewPassword} onChange={handleInputChanges}></Input>

                            <Center marginTop={"30px"} >
                                <Button bgColor={"#00A551"} color={"#FFFFFF"} width={"120px"} height={"30px"} borderRadius={"15px"} alignContent={"center"} onClick={updatePassword}>
                                    Submit
                                </Button>
                            </Center>
                        </FormControl>
                    </Box>
                </Center>
            </Box>
        </>
    )
};

export default ForgotPassword;