import { Box, Flex, Text, Center, Image, Heading, useToast, Avatar, Wrap, } from "@chakra-ui/react";
import income from "../../assets/income.png";
import notPaid from "../../assets/outcome.png";
import alreadyPaid from "../../assets/incomeblue.png";
import SidebarSuperAdmin from "../../components/SidebarSuperAdmin";
import { useState } from "react";
import api from "../../services/api";
import { useEffect } from "react";

const Home = () => {
    // toast
    const toast = useToast();

    // state 
    const [incomeDaily, setIncomeDaily] = useState({});
    const [incomeMonthly, setIncomeMonthly] = useState({});
    const [profile, setProfile] = useState({}); // set profile users

    // get api daily income informations 
    const getIncomeDaily = async() => {
        try {
            const response = await api.get(`/api/v6/recap/income/today`);
            if (response.data.status_code = 200) {
                setIncomeDaily(response.data.payload)
                console.log(response.data.payload)
            }
        } catch (error) {
            console.log("Error Fetching Data", error);
            toast({
                title: 'Error.!',
                description: `${error.response.data.message}`,
                status: 'error',
                duration: 1500,
                isClosable: true,
                position: 'top',
            });
        }
    };

    // get api monthly income informations 
    const getIncomeMonthly = async() => {
        try {
            const response = await api.get(`/api/v6/recap/income/monthly`);
            if (response.data.status_code = 200) {
                setIncomeMonthly(response.data.payload)
            }
        } catch (error) {
            console.log("Error Fetching Data", error);
            toast({
                title: 'Error.!',
                description: `${error.response.data.message}`,
                status: 'error',
                duration: 1500,
                isClosable: true,
                position: 'top',
            });
        }
    };

    // get data user by token 
    const getUsers = async() => {
        const token = localStorage.getItem('access_token');
        // console.log(token)
        try {
            if (token !== null) {
                const response = await api.get(`/api/v1/users/user/profile/${token}`);
                if (response.data.status_code === 200) {
                    setProfile(response.data.payload);
                }
                // console.log(response.data.payload)
            }
        } catch (error) {
            console.log("Error Fetching Data", error);
            toast({
                title: 'Error.!',
                description: `${error.response.data.message}`,
                status: 'error',
                duration: 1500,
                isClosable: true,
                position: 'top',
            }); 
        }
    }

    // console.log(incomeMonthly)
    useEffect(() => {
        getIncomeDaily();
        getIncomeMonthly();
        getUsers();
    }, []);
    
    return(
        <>
            <Box bgColor={"#F5F7FA"} bgSize={"cover"} height={"100vh"} display={"flex"}>
                <SidebarSuperAdmin/>  

                <Box margin={"50px"} width={"100%"} display={"flex"} flexDirection={"column"} marginLeft={"280px"} > 
    
                    <Box marginLeft={"10px"} marginTop={"20px"} display={"flex"} flexDir={"row"}>
                        <Box> 
                            <Heading>Dashboard</Heading>    
                        </Box>
                        
                        <Box marginLeft={"auto"}>
                            <Wrap spacing={"15px"}>
                                <Text fontWeight={"semibold"} fontSize={"xl"} marginTop={"9px"}>{profile?.full_name ? profile.full_name.split(' ').slice(0, 2).join(' ') : ''}</Text>
                                <Avatar name={profile?.full_name ?? ''}></Avatar>
                            </Wrap>
                        </Box>
                    </Box>

                    <Box padding={"30px"} display={"flex"} flexDirection={"column"} bgColor={"#FFFFFF"} borderRadius={"30px"} marginTop={"40px"} minHeight={"75vh"}>
                        <Center fontSize={"3xl"} fontWeight={"semibold"} paddingTop={"50px"}>Income Information Daily</Center>

                        <Flex direction={{base: "column", md: "row"}} justifyContent={"space-between"} marginTop={{base: "4", md: "8"}} marginLeft={{base: "4", md: "8"}} marginRight={{base: "4", md: "8"}}>
                            <Box bgColor={"#F5F7FA"} width={{base: "100%", md: "30%"}} height={{base: "150px", md: "100px"}} borderRadius={"20px"} display={"flex"} marginBottom={{base: "4", md: "0"}}>
                                <Image src={income} width={"76px"} height={"76px"} marginTop={"10px"} marginLeft={"20px"}/>
                                <Box marginTop={"20px"} marginLeft={"20px"}>
                                    <Text fontWeight={"semibold"} fontSize={{base: "xl", md: "2xl"}}>Total Omset</Text>
                                    <Text fontWeight={"medium"} fontSize={{base: "md", md: "xl"}}>Rp. {incomeDaily?.totalTodayPrice?.toLocaleString('id-ID') ?? '0'}</Text>
                                </Box>
                            </Box>

                            <Box bgColor={"#F5F7FA"} width={{base: "100%", md: "30%"}} height={{base: "150px", md: "100px"}} borderRadius={"20px"} display={"flex"} marginBottom={{base: "4", md: "0"}}>
                                <Image src={notPaid} width={"76px"} height={"76px"} marginTop={"10px"} marginLeft={"20px"}/>
                                <Box marginTop={"20px"} marginLeft={"20px"}>
                                    <Text fontWeight={"semibold"} fontSize={{base: "xl", md: "2xl"}}>Not Yet Paid</Text>
                                    <Text fontWeight={"medium"} fontSize={{base: "md", md: "xl"}}>Rp. {incomeDaily?.notYetPaid?.toLocaleString('id-ID')}</Text>
                                </Box>
                            </Box>      

                            <Box bgColor={"#F5F7FA"} width={{base: "100%", md: "30%"}} height={{base: "150px", md: "100px"}} borderRadius={"20px"} display={"flex"} marginBottom={{base: "4", md: "0"}}>
                                <Image src={alreadyPaid} width={"76px"} height={"76px"} marginTop={"10px"} marginLeft={"20px"}/>
                                <Box marginTop={"20px"} marginLeft={"20px"}>
                                    <Text fontWeight={"semibold"} fontSize={{base: "xl", md: "2xl"}}>Already Paid</Text>
                                    <Text fontWeight={"medium"} fontSize={{base: "md", md: "xl"}}>Rp. {incomeDaily?.alreadyPaid?.toLocaleString('id-ID')}</Text>
                                </Box>
                            </Box>   
                        </Flex>

                        <Center fontSize={"3xl"} fontWeight={"semibold"} paddingTop={"50px"}>Income Information Monthly</Center>

                        <Flex direction={{base: "column", md: "row"}} justifyContent={"space-between"} marginTop={{base: "4", md: "8"}} marginLeft={{base: "4", md: "8"}} marginRight={{base: "4", md: "8"}}>
                            <Box bgColor={"#F5F7FA"} width={{base: "100%", md: "30%"}} height={{base: "150px", md: "100px"}} borderRadius={"20px"} display={"flex"} marginBottom={{base: "4", md: "0"}}>
                                <Image src={income} width={"76px"} height={"76px"} marginTop={"10px"} marginLeft={"20px"}/>
                                <Box marginTop={"20px"} marginLeft={"20px"}>
                                    <Text fontWeight={"semibold"} fontSize={{base: "xl", md: "2xl"}}>Total Omset</Text>
                                    <Text fontWeight={"medium"} fontSize={{base: "md", md: "xl"}}>Rp. {incomeMonthly?.totalMonthlyPrice?.toLocaleString('id-ID')}</Text>
                                </Box>
                            </Box>

                            <Box bgColor={"#F5F7FA"} width={{base: "100%", md: "30%"}} height={{base: "150px", md: "100px"}} borderRadius={"20px"} display={"flex"} marginBottom={{base: "4", md: "0"}}>
                                <Image src={notPaid} width={"76px"} height={"76px"} marginTop={"10px"} marginLeft={"20px"}/>
                                <Box marginTop={"20px"} marginLeft={"20px"}>
                                    <Text fontWeight={"semibold"} fontSize={{base: "xl", md: "2xl"}}>Not Yet Paid</Text>
                                    <Text fontWeight={"medium"} fontSize={{base: "md", md: "xl"}}>Rp. {incomeMonthly?.notYetPaid?.toLocaleString('id-ID')}</Text>
                                </Box>
                            </Box>      

                            <Box bgColor={"#F5F7FA"} width={{base: "100%", md: "30%"}} height={{base: "150px", md: "100px"}} borderRadius={"20px"} display={"flex"} marginBottom={{base: "4", md: "0"}}>
                                <Image src={alreadyPaid} width={"76px"} height={"76px"} marginTop={"10px"} marginLeft={"20px"}/>
                                <Box marginTop={"20px"} marginLeft={"20px"}>
                                    <Text fontWeight={"semibold"} fontSize={{base: "xl", md: "2xl"}}>Already Paid</Text>
                                    <Text fontWeight={"medium"} fontSize={{base: "md", md: "xl"}}>Rp. {incomeMonthly?.alreadyPaid?.toLocaleString('id-ID')}</Text>
                                </Box>
                            </Box>   
                        </Flex>
                    </Box>

                </Box>    
            </Box>   
        </>
    )
};

export default Home;