import { Box, Button, Center, FormControl, FormLabel, Heading, Input, Link, Spinner, Text, useToast } from "@chakra-ui/react";
import axios from "axios";
import { useState } from "react";
import api from "../services/api";

const Login = () => {

    const toast = useToast()

    // show and hide password 
    const [showPassword, setShowPassword] = useState(false);

    // toggle hide and show password 
    // const toggleShowPassword = () => {
    //     setShowPassword(!showPassword);
    // };

    // set username dan password disimpan pada state 
    const [credentials, setCredentials] = useState({
        username: '',
        password: '',
    });

    // console.log(credentials)
    // functions to input changes 
    const handleInputChanges = (event) => {
        const { name, value } = event.target;
        setCredentials({
            ...credentials,
            [name]: value,
        });
    };

    // set loading 
    const [isLoading, setIsLoading] = useState(false);

    // function untuk login 
    const handleLogin = async () => {
        setIsLoading(true);
        try {
            const response = await api.post('/api/v2/auth/login', credentials)
            console.log(response.data.full_name)
            // kondisional jika berhasil login
            if (response.status === 200) {
                const token = response.data.token;
                // simpan token pada localstorage 
                localStorage.setItem('access_token', token);
                
                if (response.data.role === 'superadmin' || response.data.role === 'superAdmin') {
                    setTimeout(() => {
                        window.location.href = '/backoffice/dashboard'  
                    }, 2000);

                    // menampilkan alert
                    toast({
                        title: 'Login Succesfull!',
                        description: `Welcome ${response.data.full_name.split(' ').slice(0, 2).join(' ')}`,
                        status: 'success',
                        duration: 2000,
                        isClosable: true,
                        position: 'top',
                    });
                } 
              
                if (response.data.role === 'admin') {
                    setTimeout(() => {
                        window.location.href = '/admin/dashboard'  
                    }, 2000);

                    // menampilkan alert
                    toast({
                        title: 'Login Succesfull!',
                        description: `Welcome ${response.data.full_name.split(' ').slice(0, 2).join(' ')}`,
                        status: 'success',
                        duration: 2000,
                        isClosable: true,
                        position: 'top',
                    });
                }
            }else if (response.status === 401) {
                toast({
                    title: 'Invalid Username or Password!',
                    status: 'error',
                    duration: 2000,
                    isClosable: true,
                    position: 'top'
                });
            }
          
        } catch (error) {
            console.log(error);
            toast({
                title: 'Invalid Username or Password!',
                status: 'error',
                duration: 2000,
                isClosable: true,
                position: 'top'
            });
        } finally {
            setIsLoading(false); // Set loading state to false after 2 seconds
        }
    }

    return(
        <>
            <Box height={"100vh"} bgColor={"#F5F7FA"} bgSize={"cover"}>
                <Center paddingTop={[null, "9%"]}>
                    <Box bgColor={"#FFFFFF"} padding={"50px"} display={"flex"} justifyContent={"space-between"} flexDir={"column"}>
                        <Heading textAlign={"center"} fontSize={"2xl"} color={"#00A551"}>Welcome Admin.!</Heading>
                        <Text marginTop={"10px"} color={"#474749"} fontWeight={"normal"} textAlign={"center"}>Please Input Your Username and Password For Get Access</Text>

                        <FormControl marginTop={"30px"}>
                            <FormLabel color={"#7F7E83"} fontWeight={"normal"}>
                                Username
                            </FormLabel>
                            <Input type="text" name="username" value={credentials.username} onChange={handleInputChanges}></Input>

                            <FormLabel marginTop={"20px"} color={"#7F7E83"} fontWeight={"normal"}>
                                Password
                            </FormLabel>
                            {/* <InputGroup> */}
                                <Input type={showPassword ? "text" : "password"} name="password" value={credentials.password} onChange={handleInputChanges}></Input>
                                {/* <InputRightElement>
                                    {showPassword ? <ViewOffIcon onClick={toggleShowPassword}/> : <ViewIcon onClick={toggleShowPassword}/>}
                                </InputRightElement>
                            </InputGroup> */}
                             
                            <Center marginTop={"30px"} >
                                <Button bgColor={"#00A551"} color={"#FFFFFF"} width={"120px"} height={"30px"} borderRadius={"15px"} alignContent={"center"} onClick={handleLogin}>
                                    {isLoading ? <Spinner size="sm" color="white"/> : 'Login'}
                                </Button>
                                {isLoading && (
                                    <Box
                                        position="fixed"
                                        top="0"
                                        left="0"
                                        right="0"
                                        bottom="0"
                                        overflow="hidden"
                                        zIndex="9999"
                                        backdropFilter={isLoading ? "blur(5px)" : "none"} // Blur effect conditionally
                                        backgroundColor="rgba(255, 255, 255, 0.7)"
                                        display={isLoading ? "flex" : "none"}
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Spinner size="xl" color="blue.500" />
                                    </Box>
                                )}
                            </Center>
                        </FormControl>

                        <Center gap={"5px"} marginTop={"30px"}>
                            <Text color={"#474749"} fontWeight={"normal"}>Forgot Password ?</Text>
                            <Link color={"#00A551"} textDecoration={"underline"} href="/forgotPassword">Click here</Link>
                        </Center>
                    </Box>
                </Center>
            </Box>
        </>
    )
};

export default Login;