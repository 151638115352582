import { Wrap, Avatar, Box, Text, Button, FormControl, TableContainer, Heading, Thead, Tr, Th, Td, Table, Flex, Tbody, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, useDisclosure, FormLabel, Input, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter, InputGroup, InputLeftElement, useToast } from "@chakra-ui/react";
import Sidebar from "../../components/Sidebar"
import api from "../../services/api";
import React, { useEffect, useState } from "react";
import { ArrowLeftIcon, ArrowRightIcon, SearchIcon } from "@chakra-ui/icons";
import debounce from "lodash.debounce";

const FishData = () => {

    // toast
    const toast = useToast();

    // modal open close 
    // const { isOpen, onClose, onOpen } = useDisclosure();
    const cancelRef = React.useRef();

    // confirmation modal open 
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    
    // edit
    const [openModalEdit, setOpenModalEdit] = useState(false);

    // confirmation alert open 
    // delete
    const [openAlertDelete, setOpenAlertDelete] = useState(false);

    // state data to save response data fish
    const [dataFish, setDataFish] = useState([]);

    // state data to save response data fish by Id
    const [dataFishById, setDataFishById] = useState([]);

    // state to save id before delete data 
    const [fishId, setFishId] = useState();

    // state to save data before udd data
    const [formData, setFormData] = useState({name: '', min_price: 0, max_price: 0});

    // state for paginated 
    const [page, setPage] = useState(1);

    const [profile, setProfile] = useState({}); // set profile users

     // state save search fish name 
     const[searchName, setSearchName] = useState("");

    // handle paginated
    const handleNextPage = async() => {
        if (page !== dataFish.totalPages) {
            setPage(page + 1)
        } else {
            toast({
                title: 'Warnings...!',
                description: 'You are already on the last page.',
                status: 'warning',
                duration: 1500,
                isClosable: true,
                position: 'top',
            });
        }
    };

    const handlePreviousPage = async() => {
        if (page > 1) {
            setPage(page -1)
        }
    };

    // fetching data from axios to get all fish data
    const getFish = async () => {
        let url = `/api/v3/fish/pages/page?`;

        if (page !== 0) {
            url += `page=${page}`;
        };

        if (searchName !== "") {
            url += `&name=${searchName}`;
        };
        // console.log(url)
        try {
            const response = await api.get(url);
            if (response.data.status_code === 200) {
                setDataFish(response.data.payload);
            }
            
            if (response.data.payload.data.length === 0) {
                toast({
                    title: 'Not Data Available',
                    description: "",
                    status: 'warning',
                    duration: 1500,
                    isClosable: true,
                    position: "top",
                });
            }
        } catch (error) {
            console.log(error);
            toast({
                title: 'Error.!',
                description: "",
                status: "error",
                duration: 2000,
                isClosable: true,
            });
        }
    }

    // fetching data from axios get fish by id 
    const getFishById = async (fishId) => {
        try {
            const response = await api.get(`/api/v3/fish/${fishId}`);
            setDataFishById(response.data.payload);
            // console.log(response.data.payload)
        } catch (error) {
            console.log("Error Fetching data", error);
        }
    }

    // add data function

    // set data to state
    const handleFormChange = (e) => {
        const { name, value } = e.target;
        
        // Conversion to integer
        const updatedValue = name === 'min_price' || name === 'max_price' ? parseInt(value, 10) : value;
        setFormData({...formData, [name]: updatedValue, });

        // console.log(formData)
    };

    // function add data to db 
    const postDataFish = async () => {
        // console.log(formData)
        try {
            // checking data min price must < max price 
            if (formData.min_price > formData.max_price) {
                toast({
                    title: 'Data Cannot Create',
                    description: "Min price must be less than max price",
                    status: 'warning',
                    duration: 1500,
                    isClosable: true,
                    position: "top",
                });
                return;
            }

            const response = await api.post(`api/v3/fish`, formData);

            // check if succes and show toast
            if (response.data.status_code === 200) {
                toast({
                    title: 'Data created.',
                    description: "You Have Created New Fish ",
                    status: 'success',
                    duration: 1500,
                    isClosable: true,
                    position: "top",
                })

                getFish();

                // set form data to default 
                setFormData({ name: '', min_price: 0, max_price: 0 });
            } else {
                toast({
                    title: 'Error.!',
                    description: "You Can't Created New Data",
                    status: "error",
                    duration: 2000,
                    isClosable: true,
                });
            }
        } catch (error) {
            console.log(error);
            toast({
                title: 'Error.!',
                description: "You Can't Created New Data",
                status: "error",
                duration: 2000,
                isClosable: true,
            });
        }
    }

    // update data fish 
    const updateDataFish = async (e) => {
        try {
            e.preventDefault();

            // checking data min price and max price 

            const updatedValues = {
                name: e.target.name.value,
                min_price: parseInt(e.target.min_price.value, 10),
                max_price: parseInt(e.target.max_price.value, 10),
            };
            // console.log(updatedValues)

            const fishId = e.target.id.value;
            // console.log(fishId)

            if (updatedValues !== null) {
                if (updatedValues.min_price < updatedValues.max_price) {
                    const response = await api.put(`api/v3/fish/${fishId}`, updatedValues);

                    if (response.data.status_code === 200) {
                        toast({
                            title: 'Data updated.',
                            description: 'Successfully updated the fish data.',
                            status: 'success',
                            duration: 1500,
                            isClosable: true,
                            position: 'top',
                        });
    
                        setOpenModalEdit(false);
                        getFish();
                    }
                }else{
                    toast({
                        title: 'Data Cannot Updated',
                        description: "Min price must be less than max price",
                        status: 'warning',
                        duration: 1500,
                        isClosable: true,
                        position: "top",
                    });
                    return;
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    // Delete data 

    // get fish id to delete 
    const handleDeleteClick = (fishId) => {
        setFishId(fishId);
        setOpenAlertDelete(true);
    }

    // fetch to delete fish 
    const handleDeleteFIsh = async () => {
        try {
            if (fishId !== undefined) {
                const response = await api.delete(`api/v3/fish/${fishId}`);

                // cek if response status_code = 200
                if (response.data.status_code === 200) {
                    // send notifikasi sukses 
                    toast({
                        title: `Fish with id ${fishId} deleted.`,
                        description: 'You have successfully deleted the data.',
                        status: 'success',
                        duration: 1500,
                        isClosable: true,
                        position: 'top',
                    });

                    getFish();
                }
            }else{
                toast({
                    title: 'Error.!',
                    description: 'Unable to delete data.',
                    status: 'error',
                    duration: 1500,
                    isClosable: true,
                    position: 'top',
                });
            }

            // setDataFish(undefined);
        } catch (error) {
            console.log(error);
        }
    }

    // get data user by token 
    const getUsers = async() => {
        const token = localStorage.getItem('access_token');
        // console.log(token)
        try {
            if (token !== null) {
                const response = await api.get(`/api/v1/users/user/profile/${token}`);
                if (response.data.status_code === 200) {
                    setProfile(response.data.payload);
                }
                // console.log(response.data.payload)
            }
        } catch (error) {
            console.log("Error Fetching Data", error);
            toast({
                title: 'Error.!',
                description: `${error.response.data.message}`,
                status: 'error',
                duration: 1500,
                isClosable: true,
                position: 'top',
            }); 
        }
    }

     // function get fish by name 
     const handleSearchName = debounce((e) => {
        const fishName = e.target.value;
        if (fishName !== "") {
            setSearchName(fishName) 
        }else{
            setSearchName("")
        }
    }, 1000);


    // console.log(dataFish);

    useEffect(() => {
        getFish();
        getUsers();
        if (searchName === "") {
            getFish();
        }
    }, [searchName, page]);

    return(
        <>
            <Box bgColor={"#F5F7FA"} bgSize={"cover"} height={"100vh"} display={"flex"}>
                <Sidebar/>

                <Box margin={"50px"} width={"7xl"} display={"flex"} flexDirection={"column"} marginLeft={"280px"}>
                   
                    <Box marginLeft={"10px"} marginTop={"20px"} display={"flex"} flexDir={"row"}>
                        <Box> 
                            <Heading>Fish Data</Heading>    
                        </Box>
                        
                        <Box marginLeft={"auto"}>
                            <Wrap spacing={"15px"}>
                                <Text fontWeight={"semibold"} fontSize={"xl"} marginTop={"9px"}>{profile?.full_name ? profile.full_name.split(' ').slice(0, 2).join(' ') : ''}</Text>
                                <Avatar name={profile?.full_name ?? ''}></Avatar>
                            </Wrap>
                        </Box>
                    </Box>

                    <Box padding={"30px"} display={"flex"} flexDirection={"column"} bgColor={"#FFFFFF"} borderRadius={"30px"} marginTop={"35px"} minHeight={"78vh"}>
                    <Box display={"flex"} flexDir={"row"} gap={"10"}>
                            <Box>
                                <Text marginBottom={"15px"}>Fish Name</Text>
                                <InputGroup>
                                    <InputLeftElement>
                                        <SearchIcon/>
                                    </InputLeftElement>

                                    <Input placeholder="Name" name="name" onChange={(e) => handleSearchName(e)}></Input>
                                </InputGroup>
                            </Box>
                        </Box>
                        
                        <Box bgColor={"whitesmoke"} boxSize={"6xl"} marginTop={"25px"} borderRadius={"20px"} marginLeft={"10px"} paddingBottom={"20px"} minHeight={"51.5vh"} maxHeight={"51.5vh"}>
                        <TableContainer>
                                <Table variant={"striped"}>
                                    <Thead>
                                        <Tr>
                                            <Th>No</Th>
                                            <Th>Name</Th>
                                            <Th>Minimal Price</Th>
                                            <Th>Maximal Price</Th>
                                            <Th>Action</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {dataFish.data && dataFish.data.map((fish, index) => (
                                        <Tr key={fish.id}>
                                            <Td>{(dataFish.currentPages - 1) * 5 + (index + 1)}</Td>
                                            <Td>{fish.name}</Td>
                                            <Td>{`Rp. ${fish.min_price.toLocaleString('id-ID')}`}</Td>
                                            <Td>{`Rp. ${fish.max_price.toLocaleString('id-ID')}`}</Td>
                                            <Td>
                                                <Flex gap={"2"}>
                                                    <Button size={"sm"} colorScheme="blackAlpha" borderRadius={"40px"} onClick={() => {getFishById(fish.id); setOpenModalEdit(true)}}>Detail</Button>
                                                    {/* <Button size={"sm"} colorScheme="red" borderRadius={"40px"} onClick={() => handleDeleteClick(fish.id)}>Delete</Button> */}
                                                </Flex>    
                                            </Td>                                
                                        </Tr>
                                        ))}
                                    </Tbody>
                                </Table>
                            </TableContainer>
                        </Box>

                        <Flex marginTop={"20px"} gap={"10px"} marginLeft={"15px"}>
                            <Box as="div" marginTop={"7px"} justifyContent={"flex-start"}>
                                <Text fontSize={"sm"} fontWeight={"semibold"}>Current Pages : {dataFish.currentPages}</Text>
                            </Box>

                            <Box as="div" marginTop={"7px"}>
                                <Text fontSize={"sm"} fontWeight={"semibold"}>Total Pages : {dataFish.totalPages}</Text>
                            </Box>

                            <Button marginLeft={"240px"} bgColor={"#b6e1e0"} alignItems={"center"} display={"flex"} marginBottom={"5px"} onClick={handlePreviousPage} justifyContent={"center"} size={"sm"}>
                                <ArrowLeftIcon/> 
                                <Text marginLeft={"5px"} marginBottom={"3px"}>
                                    Prev
                                </Text>
                            </Button>

                            <Button onClick={handleNextPage} marginBottom={"5px"} bgColor={"#b6e1e0"} alignItems={"center"} display={"flex"} justifyContent={"center"} size={"sm"}>
                                <ArrowRightIcon/>
                                <Text marginLeft={"5px"} marginBottom={"3px"}>
                                    Next
                                </Text>
                            </Button>
                        </Flex>
                    </Box>
                </Box>
            </Box>

            {/* Modal for detail data fish */}

            <Modal isOpen={openModalEdit} onClose={() => setOpenModalEdit(false)} size={"xl"}>
                <ModalOverlay backdropBlur="xl" backdropInvert="80%" backgroundBlendMode="darken"> 
                    <ModalContent>
                        <ModalHeader>Edit Data Fish</ModalHeader>
                        <ModalCloseButton/>

                        <ModalBody>
                            {dataFishById && (
                            <form key={dataFishById.id}>
                                <FormControl isRequired>
                                    <Box width={"70%"}>
                                        <Flex flexDir={"column"} gap={5}>
                                            <Box>
                                                <FormLabel>Id</FormLabel>
                                                <Input readOnly defaultValue={dataFishById.id} name="id"></Input>
                                            </Box>

                                            <Box>
                                                <FormLabel>Name</FormLabel>
                                                <Input type="text" name="name" defaultValue={dataFishById.name}></Input>
                                            </Box>

                                            <Box>
                                                <FormLabel>Minimal Price</FormLabel>
                                                <InputGroup>
                                                    <InputLeftElement pointerEvents="none">
                                                        Rp.
                                                    </InputLeftElement>
                                                    <Input type="number" name="min_price" defaultValue={dataFishById.min_price}></Input>
                                            </InputGroup>
                                            </Box>

                                            <Box>
                                                <FormLabel>Maximal Price</FormLabel>
                                                <InputGroup>
                                                    <InputLeftElement pointerEvents="none">
                                                        Rp.
                                                    </InputLeftElement>
                                                    <Input type="number" name="max_price" defaultValue={dataFishById.max_price}></Input>
                                            </InputGroup>
                                            </Box>

                                            <Box marginBottom={"15px"}>
                                                <Button type="submit" onClick={() => setOpenModalEdit(false)}>Back</Button>
                                            </Box>
                                        </Flex>                                 
                                    </Box>
                                </FormControl>
                            </form>
                            )}
                        </ModalBody>
                    </ModalContent>
                </ModalOverlay>
            </Modal>

            {/* Alert dialog for delete data */}
            <AlertDialog isOpen={openAlertDelete} leastDestructiveRef={cancelRef} onClose={() => setOpenAlertDelete(false)}> 
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize={"lg"} fontWeight={"bold"}>
                            Delete data
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Are you sure to Delete data fish ?
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={() => setOpenAlertDelete(false)} marginRight={"10px"}>
                                Cancel
                            </Button>

                            <Button colorScheme="red" onClick={() => {setOpenAlertDelete(false); handleDeleteFIsh()}}>
                                Delete
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}

export default FishData;

