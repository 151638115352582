// localhost vs code back end 

import axios from "axios";

const token = localStorage.getItem('access_token');
// console.log(token);

// const api = axios.create({
// 	baseURL: "http://localhost:4000",
// 	headers: {
// 		Authorization: `Bearer ${token}`
// 	},
// });

const api = axios.create({
	baseURL: "https://api.solomina.my.id",
	headers: {
		Authorization: `Bearer ${token}`
	},
});

export default api;
